import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import {
  TextField,
  Button,
  Stack,
  Card,
  CardContent,
  Box,
  CircularProgress,
  Alert,
  AlertTitle,
  IconButton,
  InputAdornment,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { CAMPO_OBRIGATORIO } from "../../util/string/utilStrings";

import axios from "axios";

import Colors from "../../components/constants/Colors";
import bg_image from "../../assets/imgs/bg.png";
import logoivitech from "../../assets/imgs/logoivitech.png";
import siga_title from "../../assets/imgs/SIGAtitle.png";

import { StyleSheet, css } from "aphrodite";

// const API_URL = 'http://192.168.3.116:8080/dxsped/api/v1';
// const API_URL = "https://aplicacao.ivitech.com.br/xsped/api/v1";

function CadastraSenha() {
  const navigate = useNavigate();

  const loc = useLocation();
  const getParam = new URLSearchParams(loc.search);
  const token = getParam.get("token");

  console.log(token);

  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [critica, setCritica] = useState(false);
  const [erro, setErro] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sucesso, setSucesso] = useState(false);

  //   const handleEnterPress = (ev) => {
  //     if (ev.key === "Enter") {
  //       alteraSenha();
  //     }
  //   };

  const handleShowAll = () => {
    setShowNewPassword(!showNewPassword);
    setShowConfirmPassword(!showConfirmPassword);
  };

  //   function alteraSenha() {
  //     setCritica(true);
  //     setLoading(true);
  //     const password = { newPassword: newPassword };

  //     if (newPassword.length === 0 || confirmPassword.length === 0) {
  //       setLoading(false);
  //     } else if (newPassword !== confirmPassword) {
  //       setLoading(false);
  //       setErrorMessage("Os dois campos devem ser iguais.");
  //       setErro(true);
  //     } else {
  //       setCritica(false);
  //       setLoading(false);
  //       setSucesso(true);
  //       axios
  //         .post(
  //           `${API_URL}/app/user/password/register?token=${token}`,
  //           password
  //           // headers()
  //         )
  //         .catch((err) => {
  //           console.log(err);
  //           setErrorMessage(
  //             "Houve um erro na requisição. Tente novamente mais tarde."
  //           );
  //           setErro(true);
  //         });
  //     }
  //   }

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          backgroundImage: `url(${bg_image})`,
          backgroundSize: "cover",
          left: 0,
          top: 0,
          minHeight: "100%",
          minWidth: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box
          mt={-15}
          mb={3}
          component={"img"}
          src={siga_title}
          alt={"SIGA"}
        ></Box>
        <Card sx={{ width: { md: "24%", sm: "30%", xs: "50%" }, mb: 2 }}>
          <CardContent>
            {!sucesso ? (
              <Stack spacing={2} width="100%" alignItems={"center"}>
                <p className={css(styles.title)}>Cadastrar Senha</p>
                <TextField
                  //   onKeyPress={handleEnterPress}
                  fullWidth
                  error={critica && newPassword.length === 0}
                  value={newPassword}
                  type={showNewPassword ? "text" : "password"}
                  onChange={(ev) => setNewPassword(ev.target.value)}
                  size={"small"}
                  color={"primary"}
                  label="Nova senha"
                  helperText={newPassword.length === 0 ? CAMPO_OBRIGATORIO : ""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowAll}
                          edge="end"
                        >
                          {showNewPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  //   onKeyPress={handleEnterPress}
                  fullWidth
                  error={critica && confirmPassword.length === 0}
                  value={confirmPassword}
                  type={showConfirmPassword ? "text" : "password"}
                  onChange={(ev) => setConfirmPassword(ev.target.value)}
                  size={"small"}
                  color={"primary"}
                  label="Confirmar senha"
                  helperText={
                    confirmPassword.length === 0 ? CAMPO_OBRIGATORIO : ""
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleShowAll}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  //   onClick={alteraSenha}
                  disabled={loading}
                  sx={{
                    backgroundColor: Colors.primary,
                    fontFamily: "Poppins",
                    color: "white",
                    fontWeight: 600,
                    letterSpacing: 1.5,
                    borderColor: Colors.primary,
                    "&:hover": {
                      backgroundColor: "green",
                      color: "white",
                      borderColor: "green",
                    },
                    "&:disabled": {
                      backgroundColor: "green",
                      color: "white",
                      borderColor: "green",
                    },
                  }}
                  fullWidth
                  variant="outlined"
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    "Confirmar"
                  )}
                </Button>
              </Stack>
            ) : (
              <Stack spacing={2} width="100%" alignItems={"center"}>
                <p className={css(styles.titleAlt)}>Nova senha cadastrada</p>
                <p className={css(styles.text)}>
                  Seu acesso no SIGA foi liberado.
                </p>

                <button
                  className={css(styles.link)}
                  onClick={() => navigate("/")}
                >
                  VOLTAR
                </button>
              </Stack>
            )}
          </CardContent>
        </Card>
        {erro && (
          <Alert
            severity="error"
            sx={{ width: "50%", zIndex: 2 }}
            closeText={"Fechar mensagem"}
            onClose={() => setErro(false)}
          >
            <AlertTitle>Erro</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            textAlign: "center",
          }}
        >
          <Box
            mt={7}
            mb={1.5}
            component={"img"}
            src={logoivitech}
            alt={"Ivitech Tecnologia"}
          />
          <p className={css(styles.lgpd)}>
            Adequada à Lei Geral de Proteção de Dados.
          </p>
        </div>
      </Box>
    </>
  );
}

const styles = StyleSheet.create({
  title: {
    color: "#4b4b4b",
    fontFamily: "Poppins",
    marginTop: "-0.15rem",
    marginBottom: "-0.20rem",
    fontSize: 14,
    fontWeight: 600,
  },
  titleAlt: {
    color: "green",
    fontFamily: "Poppins",
    marginTop: "-0.15rem",
    marginBottom: "-0.20rem",
    fontSize: 14,
    fontWeight: 600,
  },
  text: {
    color: "#4b4b4b",
    fontFamily: "Poppins",
    fontSize: 13,
    fontWeight: 500,
  },
  lgpd: {
    color: "#fff",
    fontFamily: "Poppins",
    marginTop: "-0.15rem",
    fontSize: 13,
  },
  input: {
    fontSize: 12,
    // fontFamily: "Poppins",
  },
  link: {
    backgroundColor: "transparent",
    border: "none",
    color: "#6163B0",
    fontSize: 13,
    fontFamily: "Poppins",
    fontWeight: 600,
    letterSpacing: 2,
    textDecoration: "underline",
    cursor: "pointer",

    ":active": {
      color: "red",
    },
  },
});

export default CadastraSenha;
