export const columns = [
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "left",
    field: "ref",
    headerName: "Referência",
    headerAlign: "left",
    isNumber: false,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "left",
    field: "nome",
    headerName: "Descrição",
    headerAlign: "left",
    isNumber: false,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "right",
    field: "emitidas",
    headerName: "Emitidas",
    headerAlign: "right",
    isNumber: true,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "right",
    field: "preparacao",
    headerName: "Preparação",
    headerAlign: "right",
    isNumber: true,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "right",
    field: "corte",
    headerName: "Corte",
    headerAlign: "right",
    isNumber: true,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "right",
    field: "oficina",
    headerName: "Oficina",
    headerAlign: "right",
    isNumber: true,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "right",
    field: "bordado",
    headerName: "Bordado",
    headerAlign: "right",
    isNumber: true,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "right",
    field: "acabamento",
    headerName: "Acabamento",
    headerAlign: "right",
    isNumber: true,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "right",
    field: "produzidas",
    headerName: "Produzidas",
    headerAlign: "right",
    isNumber: true,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "right",
    field: "total",
    headerName: "Total",
    headerAlign: "right",
    isNumber: true,
    sortable: false,
    minWidth: 150,
  },
];

export const data = [
  {
    ref: "Referência 1",
    nome: "PRODUTO A",
    emitidas: "10",
    preparacao: "15",
    corte: "10",
    oficina: "20",
    bordado: "15",
    acabamento: "20",
    produzidas: "10",
    total: "100",
    tipoProd: "prodAcabado",
    emiIni: "01/01/2025",
    emiFim: "15/01/2025",
    previIni: "01/01/2025",
    previFim: "25/01/2025",
  },
  {
    ref: "Referência 2",
    nome: "PRODUTO B",
    emitidas: "30",
    preparacao: "10",
    corte: "10",
    oficina: "10",
    bordado: "20",
    acabamento: "10",
    produzidas: "10",
    total: "100",
    tipoProd: "prodAcabado",
    emiIni: "01/01/2025",
    emiFim: "16/01/2025",
    previIni: "01/01/2025",
    previFim: "26/01/2025",
  },
  {
    ref: "Referência 3",
    nome: "PRODUTO C",
    emitidas: "15",
    preparacao: "15",
    corte: "20",
    oficina: "15",
    bordado: "15",
    acabamento: "10",
    produzidas: "10",
    total: "100",
    tipoProd: "prodAcabado",
    emiIni: "01/01/2025",
    emiFim: "17/01/2025",
    previIni: "01/01/2025",
    previFim: "27/01/2025",
  },
  {
    ref: "Referência 4",
    nome: "PRODUTO D",
    emitidas: "10",
    preparacao: "10",
    corte: "20",
    oficina: "20",
    bordado: "5",
    acabamento: "30",
    produzidas: "5",
    total: "100",
    tipoProd: "insumo",
    emiIni: "01/01/2025",
    emiFim: "18/01/2025",
    previIni: "01/01/2025",
    previFim: "28/01/2025",
  },
  {
    ref: "Referência 5",
    nome: "PRODUTO E",
    emitidas: "25",
    preparacao: "25",
    corte: "10",
    oficina: "5",
    bordado: "10",
    acabamento: "10",
    produzidas: "15",
    total: "100",
    tipoProd: "insumo",
    emiIni: "01/01/2025",
    emiFim: "19/01/2025",
    previIni: "01/01/2025",
    previFim: "29/01/2025",
  },
  {
    ref: "Referência 6",
    nome: "PRODUTO F",
    emitidas: "5",
    preparacao: "5",
    corte: "5",
    oficina: "5",
    bordado: "10",
    acabamento: "5",
    produzidas: "65",
    total: "100",
    tipoProd: "insumo",
    emiIni: "01/01/2025",
    emiFim: "06/02/2025",
    previIni: "01/01/2025",
    previFim: "16/02/2025",
  },
  {
    ref: "Referência 7",
    nome: "PRODUTO G",
    emitidas: "30",
    preparacao: "5",
    corte: "40",
    oficina: "5",
    bordado: "5",
    acabamento: "5",
    produzidas: "10",
    total: "100",
    tipoProd: "insumo",
    emiIni: "01/01/2025",
    emiFim: "07/02/2025",
    previIni: "01/01/2025",
    previFim: "17/02/2025",
  },
  {
    ref: "Referência 8",
    nome: "PRODUTO H",
    emitidas: "25",
    preparacao: "10",
    corte: "25",
    oficina: "5",
    bordado: "10",
    acabamento: "10",
    produzidas: "15",
    total: "100",
    tipoProd: "insumo",
    emiIni: "01/01/2025",
    emiFim: "08/02/2025",
    previIni: "01/01/2025",
    previFim: "18/02/2025",
  },
  {
    ref: "Referência 9",
    nome: "PRODUTO I",
    emitidas: "20",
    preparacao: "10",
    corte: "10",
    oficina: "20",
    bordado: "10",
    acabamento: "10",
    produzidas: "20",
    total: "100",
    tipoProd: "insumo",
    emiIni: "01/01/2025",
    emiFim: "09/02/2025",
    previIni: "01/01/2025",
    previFim: "19/02/2025",
  },
  {
    ref: "Referência 10",
    nome: "PRODUTO J",
    emitidas: "50",
    preparacao: "15",
    corte: "5",
    oficina: "5",
    bordado: "5",
    acabamento: "10",
    produzidas: "10",
    total: "100",
    tipoProd: "insumo",
    emiIni: "01/01/2025",
    emiFim: "10/02/2025",
    previIni: "01/01/2025",
    previFim: "20/02/2025",
  },
];

export const totalizador = {
  emitidas: "220",
  preparacao: "120",
  corte: "155",
  oficina: "110",
  bordado: "105",
  acabamento: "120",
  produzidas: "170",
  total: "1000",
};

export const listaProcessos = [
  { value: "emitidas", label: "EMITIDAS" },
  { value: "preparacao", label: "PREPARAÇÃO" },
  { value: "corte", label: "CORTE" },
  { value: "oficina", label: "OFICINA" },
  { value: "bordado", label: "BORDADO" },
  { value: "acabamento", label: "ACABAMENTO" },
  { value: "emitidas", label: "PRODUZIDAS" },
];

export const listaTipos = [
  { value: "prodAcabado", label: "Produto acabado" },
  { value: "insumo", label: "Insumo" },
];
