import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  TextField,
  Button,
  Stack,
  Card,
  CardContent,
  Box,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";

import { useAppDispatch } from "../../store/hooks";
import { login } from "../../store/auth/auth-actions";

import { CAMPO_OBRIGATORIO } from "../../util/string/utilStrings";

import Colors from "../../components/constants/Colors";
import bg_image from "../../assets/imgs/bg.png";
import logoivitech from "../../assets/imgs/logoivitech.png";
import siga_title from "../../assets/imgs/SIGAtitle.png";

import { StyleSheet, css } from "aphrodite";

interface loginBody {
  userName: string;
  password: string;
  tenant: string;
  uuid: number;
}

const LoginPage = (): JSX.Element => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [tenant, setTenant] = useState("");
  const [isFieldsEmpty, setIsFieldsEmpty] = useState({
    isUserNameEmpty: false,
    isPasswordEmpty: false,
    isTenantEmpty: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleTenantChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const emptyFields = { ...isFieldsEmpty };
    if (emptyFields.isTenantEmpty) {
      emptyFields.isTenantEmpty = false;
    }
    setIsFieldsEmpty({ ...emptyFields });
    setTenant(ev.target.value);
  };
  const handleUserNameChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const emptyFields = { ...isFieldsEmpty };
    if (emptyFields.isUserNameEmpty) {
      emptyFields.isUserNameEmpty = false;
    }
    setIsFieldsEmpty({ ...emptyFields });
    setUserName(ev.target.value);
  };
  const handlePasswordChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const emptyFields = { ...isFieldsEmpty };
    if (emptyFields.isPasswordEmpty) {
      emptyFields.isPasswordEmpty = false;
    }
    setIsFieldsEmpty({ ...emptyFields });
    setPassword(ev.target.value);
  };

  const handleEnterPress = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === "Enter") {
      submitLogin();
    }
  };

  const critiques = (): boolean => {
    const emptyFields = { ...isFieldsEmpty };
    if (!userName || !password || !tenant) {
      if (!userName) {
        emptyFields.isUserNameEmpty = true;
      }
      if (!password) {
        emptyFields.isPasswordEmpty = true;
      }
      if (!tenant) {
        emptyFields.isTenantEmpty = true;
      }
      setIsFieldsEmpty({ ...emptyFields });
      return true;
    }
    emptyFields.isPasswordEmpty = false;
    emptyFields.isTenantEmpty = false;
    emptyFields.isUserNameEmpty = false;
    setIsFieldsEmpty({ ...emptyFields });
    return false;
  };

  // const asyncLocalStorage = {
  //   async setItem(key: any, value: any) {
  //     await null;
  //     return localStorage.setItem(key, value);
  //   },
  //   async getItem(key: any) {
  //     await null;
  //     return localStorage.getItem(key);
  //   },
  // };

  // let teste;

  const submitLogin = async (): Promise<void> => {
    // console.log("Entry");
    if (critiques()) return;
    setIsLoading(true);
    const tenantArray: string[] = tenant.split("@");

    localStorage.setItem("userName", userName);
    localStorage.setItem("empName", tenantArray[0]);

    const loginData: loginBody = {
      tenant: tenantArray[0],
      userName: userName,
      password: password,
      uuid: 1,
    };
    const body: string = JSON.stringify(loginData);

    try {
      // console.log("Access");
      // console.log(tenantArray[0]);
      await dispatch(login(body, tenantArray[1] ? tenantArray[1] : ""));
      setIsLoading(false);
      navigate("/main");
    } catch (err: any) {
      console.log(err.message);
      setErrorMessage(err.message as string);
      setIsLoading(false);
      setIsError(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          backgroundImage: `url(${bg_image})`,
          backgroundSize: "cover",
          left: 0,
          top: 0,
          minHeight: "100%",
          minWidth: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box
          mt={-15}
          mb={3}
          component={"img"}
          src={siga_title}
          alt={"SIGA"}
        ></Box>
        <Card sx={{ width: { md: "20%", sm: "50%", xs: "80%" }, mb: 2 }}>
          <CardContent>
            <Stack spacing={2} width="100%" alignItems={"center"}>
              <TextField
                onKeyPress={handleEnterPress}
                fullWidth
                error={isFieldsEmpty.isTenantEmpty}
                // placeholder="Empresa"
                value={tenant}
                type="text"
                onChange={handleTenantChange}
                size={"small"}
                color="success"
                label="Empresa"
                helperText={
                  isFieldsEmpty.isTenantEmpty ? CAMPO_OBRIGATORIO : ""
                }
                // inputProps={{ style: { fontSize: 14, fontFamily: "Poppins" } }}
              />
              <TextField
                onKeyPress={handleEnterPress}
                fullWidth
                error={isFieldsEmpty.isUserNameEmpty}
                // placeholder="Nome de Usuário ou E-mail"
                value={userName}
                type="text"
                onChange={handleUserNameChange}
                size={"small"}
                color={"success"}
                label="Nome de Usuário"
                helperText={
                  isFieldsEmpty.isUserNameEmpty ? CAMPO_OBRIGATORIO : ""
                }
                // inputProps={{ style: { fontSize: 14, fontFamily: "Poppins" } }}
              />
              <TextField
                onKeyPress={handleEnterPress}
                fullWidth
                error={isFieldsEmpty.isPasswordEmpty}
                // placeholder="Senha"
                value={password}
                type="password"
                onChange={handlePasswordChange}
                size={"small"}
                color="success"
                label="Senha"
                helperText={
                  isFieldsEmpty.isPasswordEmpty ? CAMPO_OBRIGATORIO : ""
                }
                // inputProps={{ style: { fontSize: 14, fontFamily: "Poppins" } }}
              />

              <Button
                onClick={submitLogin}
                disabled={isLoading}
                sx={{
                  backgroundColor: Colors.primary,
                  fontFamily: "Poppins",
                  color: "white",
                  fontWeight: 600,
                  letterSpacing: 1.5,
                  borderColor: Colors.primary,
                  "&:hover": {
                    backgroundColor: "green",
                    color: "white",
                    borderColor: "green",
                  },
                  "&:disabled": {
                    backgroundColor: "green",
                    color: "white",
                    borderColor: "green",
                  },
                }}
                fullWidth
                variant="outlined"
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  "Entrar"
                )}
              </Button>

              {/* <p className={css(styles.link)}>
                <Link to="/recuperar-senha">ESQUECI MINHA SENHA</Link>
              </p> */}
            </Stack>
          </CardContent>
        </Card>
        {isError && (
          <Alert
            severity="error"
            sx={{ width: "50%", zIndex: 2 }}
            closeText={"Fechar mensagem"}
            onClose={() => setIsError(false)}
          >
            <AlertTitle>Falha no login</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            textAlign: "center",
          }}
        >
          <Box
            mt={7}
            mb={1.5}
            component={"img"}
            src={logoivitech}
            alt={"Ivitech Tecnologia"}
          />
          <p className={css(styles.lgpd)}>
            Adequada à Lei Geral de Proteção de Dados.
          </p>
        </div>
      </Box>
    </>
  );
};

const styles = StyleSheet.create({
  lgpd: {
    color: "#fff",
    fontFamily: "Poppins",
    marginTop: "-0.15rem",
    fontSize: 13,
  },
  input: {
    fontSize: 12,
    // fontFamily: "Poppins",
  },
  link: {
    fontSize: 13,
    fontFamily: "Poppins",
    fontWeight: 600,
    letterSpacing: 2,
  },
});

export default LoginPage;
