import React, { useState, useEffect } from "react";

import axios from "axios";
import { logout } from "../../store/auth/auth-actions";

import { StyleSheet, css } from "aphrodite";
import { Link, useMatch } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import RequestPageOutlinedIcon from "@mui/icons-material/RequestPageOutlined";
import LogoutIcon from "@mui/icons-material/LogoutOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import FactoryOutlinedIcon from "@mui/icons-material/FactoryOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";

import Divider from "@mui/material/Divider";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";

import iconSiga1 from "../../assets/imgs/siga-icos-01.svg";
import iconSiga2 from "../../assets/imgs/siga-icos-02.svg";
import logoIvitech_colorido from "../../assets/imgs/logoivitech_colorido.png";
import ivitech_reduced from "../../assets/imgs/ivitech_reduced.png";

import "../../assets/styles/drawer.css";

import { headers } from "../core/store/localStorage";
import errorHandler from "../core/store/errorHandler";

// const API_URL = localStorage.getItem("apiUrl");
// const userId = localStorage.getItem("userId");

const asyncLocalStorage = {
  async getItem(key: any) {
    await null;
    return localStorage.getItem(key);
  },
};

interface Props {
  open: boolean;
  openDialog: boolean;
  openQuerySubMenu: boolean;
  toggleDialog: () => void;
  toggleQueryMenu: () => void;
}

const DrawerItemsList: any = (props: Props) => {
  const [pages, setPages] = useState<any>("");
  const [API_URL, setCurrentAPI] = useState<any>("");
  const [userId, setCurrentUserID] = useState<any>("");

  useEffect(() => {
    getAPI();
    getUserID();

    if (
      API_URL === null ||
      userId === null ||
      API_URL === undefined ||
      userId === undefined
    ) {
      window.location.reload();
    }

    console.log(userId);
  }, []);

  useEffect(() => {
    getUserAccess();
  }, [API_URL, userId]);

  const { open, openDialog, openQuerySubMenu, toggleDialog, toggleQueryMenu } =
    props;

  const [openVendasSubMenu, setOpenVendasSubMenu] = useState(false);
  const [openProdSubMenu, setOpenProdSubMenu] = useState(false);

  function toggleVendasMenu() {
    setOpenVendasSubMenu(!openVendasSubMenu);
  }

  function toggleProducaoMenu() {
    setOpenProdSubMenu(!openProdSubMenu);
  }

  function getAPI() {
    asyncLocalStorage.getItem("apiUrl").then((response) => {
      setCurrentAPI(response);
    });
  }
  function getUserID() {
    asyncLocalStorage.getItem("userId").then((response) => {
      setCurrentUserID(response);
    });
  }

  async function getUserAccess() {
    await axios
      .get(`${API_URL}/user/${userId}/tela`, headers())
      .then((response) => {
        let resp: any = [];
        response.data.data.map((res: any) => {
          resp.push(res.name);
        });
        setPages(resp);
        console.log(resp);
      })
      .catch((err) => {
        errorHandler(err);
      });
  }

  function clearData() {
    localStorage.clear();
  }

  return (
    <>
      <List style={{ marginTop: "-0.5rem" }}>
        {pages.includes("produtos") ? (
          <Link to={"/produtos"} className={css(styles.noDecoration)}>
            <ListItemButton
              // selected={!!useMatch("/produtos/*")}
              key={"Produtos"}
              sx={{
                minHeight: 15,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              title={open ? "" : "Produtos"}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 1.4 : "auto",
                  ml: open ? "" : -0.2,
                  justifyContent: "center",
                }}
              >
                <ShoppingBagOutlinedIcon />
              </ListItemIcon>
              <ListItemText
                primary={"Produtos"}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </Link>
        ) : (
          ""
        )}

        {pages.includes("vendas/referencia") ||
        pages.includes("vendas/curvaABC-referencia") ||
        pages.includes("vendas/referencia") ? (
          <ListItemButton
            onClick={toggleVendasMenu}
            sx={{
              minHeight: 15,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            title={open ? "" : "Vendas"}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.4 : "auto",
                justifyContent: "center",
              }}
            >
              <AttachMoneyOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Vendas"} sx={{ opacity: open ? 1 : 0 }} />
            {openVendasSubMenu ? (
              <ExpandLess style={{ width: 20 }} color="action" />
            ) : (
              <ExpandMore style={{ width: 20 }} color="action" />
            )}
          </ListItemButton>
        ) : (
          ""
        )}

        {openVendasSubMenu && <Divider />}
        <Collapse in={openVendasSubMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {pages.includes("/vendas/autorizacao-de-vendas") ? (
              <Link
                to={"/vendas/autorizacao-de-vendas"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/vendas/autorizacao-de-vendas/*")}
                  key={"autorizaVendas"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  title={open ? "" : "Autorização de Vendas"}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1.5 : "auto",
                      ml: open ? "" : -0.2,
                      justifyContent: "center",
                    }}
                  >
                    <CreditScoreOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Autorização de Vendas"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("vendas/curvaABC-clientes") ? (
              <Link
                to={"/vendas/curvaABC-clientes"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/vendas/curvaABC-clientes/*")}
                  key={"curvaAVC-clientes"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  title={open ? "" : "Curva ABC - Clientes"}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? -16.02 : "auto",
                      ml: open ? -17.55 : 0,
                      justifyContent: "center",
                    }}
                  >
                    <img style={{ width: "8%" }} src={iconSiga1} />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Curva ABC - Clientes"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("vendas/curvaABC-referencia") ? (
              <Link
                to={"/vendas/curvaABC-referencia"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/vendas/curvaABC-referencia/*")}
                  key={"curvaAVC-referencias"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  title={open ? "" : "Curva ABC - Referências"}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? -16.02 : "auto",
                      ml: open ? -17.55 : 0,
                      justifyContent: "center",
                    }}
                  >
                    <img style={{ width: "8%" }} src={iconSiga2} />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Curva ABC - Referências"}
                    style={{ fontSize: "12 !important", fontFamily: "Poppins" }}
                    sx={{
                      opacity: open ? 1 : 0,
                    }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("vendas/equipe") ? (
              <Link to={"/vendas/equipe"} className={css(styles.noDecoration)}>
                <ListItemButton
                  // selected={!!useMatch("/vendas/equipe/*")}
                  key={"equipes"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  title={open ? "" : "Vendas por Equipe"}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1.5 : "auto",
                      ml: open ? "" : -0.2,
                      justifyContent: "center",
                    }}
                  >
                    <Diversity3OutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Por Equipe"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("/vendas/estabelecimentos") ? (
              <Link
                to={"/vendas/estabelecimentos"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/produtos/*")}
                  key={"Estabelecimentos"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  title={open ? "" : "Vendas por Estabelecimento"}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1.5 : "auto",
                      ml: open ? "" : -0.2,
                      justifyContent: "center",
                    }}
                  >
                    <StoreMallDirectoryOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Por Estabelecimento"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("/vendas/meiopagamento") ? (
              <Link
                to={"/vendas/meiopagamento"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/produtos/*")}
                  key={"Meio de Pagamento"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  title={open ? "" : "Vendas por Meio de Pagamento"}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1.5 : "auto",
                      ml: open ? "" : -0.2,
                      justifyContent: "center",
                    }}
                  >
                    <PaymentsOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Por Meio de Pagamento"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("vendas/referencia") ? (
              <Link
                to={"/vendas/referencia"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/vendas/referencia/*")}
                  key={"referencias"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  title={open ? "" : "Vendas por Referência"}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      marginLeft: open ? 1.5 : "auto",
                      mr: open ? 1.5 : "auto",
                      ml: open ? "" : -0.2,
                      justifyContent: "center",
                    }}
                  >
                    <AssignmentOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Por Referência"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("/vendas/vendedor") ? (
              <Link
                to={"/vendas/vendedor"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/vendas/vendedor/*")}
                  key={"vendedor"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  title={open ? "" : "Vendas por Vendedor"}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      marginLeft: open ? 1.5 : "auto",
                      mr: open ? 1.5 : "auto",
                      ml: open ? "" : -0.2,
                      justifyContent: "center",
                    }}
                  >
                    <PointOfSaleOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Por Vendedor"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
          </List>
        </Collapse>
        <Divider />

        {pages.includes("producao/posicao-de-producao") ||
        pages.includes("producao/posicao-das-ordens") ? (
          <ListItemButton
            onClick={toggleProducaoMenu}
            sx={{
              minHeight: 15,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            title={open ? "" : "Produção"}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.4 : "auto",
                justifyContent: "center",
              }}
            >
              <FactoryOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={"Produção"} sx={{ opacity: open ? 1 : 0 }} />
            {openProdSubMenu ? (
              <ExpandLess style={{ width: 20 }} color="action" />
            ) : (
              <ExpandMore style={{ width: 20 }} color="action" />
            )}
          </ListItemButton>
        ) : (
          ""
        )}

        {openProdSubMenu && <Divider />}
        <Collapse in={openProdSubMenu} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {pages.includes("/producao/posicao-dos-processos") ? (
              <Link
                to={"/producao/posicao-dos-processos"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/vendas/autorizacao-de-vendas/*")}
                  key={"posicaoProcessos"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  title={open ? "" : "Posição dos Processos"}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 1.5 : "auto",
                      ml: open ? "" : -0.2,
                      justifyContent: "center",
                    }}
                  >
                    <WarehouseOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Posição dos Processos"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}

            {pages.includes("/producao/posicao-das-oficinas") ? (
              <Link
                to={"/producao/posicao-das-oficinas"}
                className={css(styles.noDecoration)}
              >
                <ListItemButton
                  // selected={!!useMatch("/vendas/vendedor/*")}
                  key={"posicaoOficinas"}
                  sx={{
                    minHeight: 15,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  title={open ? "" : "Posição das Oficinas"}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      marginLeft: open ? 1.5 : "auto",
                      mr: open ? 1.5 : "auto",
                      ml: open ? "" : -0.2,
                      justifyContent: "center",
                    }}
                  >
                    <FactCheckOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={"Posição das Oficinas"}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </Link>
            ) : (
              ""
            )}
          </List>
        </Collapse>
        <Divider />

        {/* <Divider /> */}
        <Link to={"/"} className={css(styles.noDecoration)}>
          <ListItemButton
            key={"Sair"}
            sx={{
              minHeight: 15,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            title={open ? "" : "Sair"}
            onClick={logout}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 1.4 : "auto",
                ml: open ? 0.2 : "",
                justifyContent: "center",
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={"Sair"} sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </Link>
      </List>

      <div>
        <Link to={"https://ivitech.com.br"} target="_blank">
          <img
            src={open ? logoIvitech_colorido : ivitech_reduced}
            style={{
              position: "absolute",
              bottom: 20,
              left: open ? "27%" : "15%",
              width: open ? "6.5rem" : "2.5rem",
            }}
          />
        </Link>
      </div>
    </>
  );
};

const styles = StyleSheet.create({
  noDecoration: {
    textDecoration: "none",
    color: "inherit",
  },
});

export default DrawerItemsList;
