import React, { useState, useEffect, useRef } from "react";
import FilterOficinas from "../../../components/filter/FilterOficinas";
import {
  columns,
  data,
  totalizador,
} from "../posicao_das_oficinas/mockupOficinas";
import CustomDatagrid from "../../vendas/datagrid/CustomDatagrid";

import axios from "axios";
import { Link, useLocation } from "react-router-dom";

import { Box, Grid, Pagination, PaginationItem } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import { StyleSheet, css } from "aphrodite";
import Colors from "../../../components/constants/Colors";

import "../../../assets/styles/text.css";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";

import { headers } from "../../../components/core/store/localStorage";
import errorHandler from "../../../components/core/store/errorHandler";
const API_URL = localStorage.getItem("apiUrl");

const currentUser = localStorage.getItem("userName");
const currentEmp = localStorage.getItem("empName");

function PosicaoOficinas() {
  function startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  const dt = new Date();

  const pgTitle = "Posição das Oficinas";

  const [oficina, setOficina] = useState("");
  const [ref, setRef] = useState("");
  const [inicioEmissao, setInicioEmissao] = useState(startOfMonth(dt));
  const [fimEmissao, setFimEmissao] = useState(dt);
  const [inicioPrevisao, setInicioPrevisao] = useState(startOfMonth(dt));
  const [fimPrevisao, setFimPrevisao] = useState(dt);
  const [showSaldo, setShowSaldo] = useState(false);

  const [head, setHead] = useState("");
  const [rows, setRows] = useState("");
  const [totals, setTotals] = useState("");

  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [erro, setErro] = useState("");

  useEffect(() => {
    if (API_URL === null) {
      window.location.reload();
    }
  }, []);

  function updateInicioEmissao(e) {
    setInicioEmissao(e);
  }
  function updateFimEmissao(e) {
    setFimEmissao(e);
  }
  function updateInicioPrevisao(e) {
    setInicioPrevisao(e);
  }
  function updateFimPrevisao(e) {
    setFimPrevisao(e);
  }
  function updateOficina(e) {
    setOficina(e);
  }
  function updateRef(e) {
    setRef(e);
  }
  function switchSaldo() {
    setShowSaldo(!showSaldo);
  }

  const searchProducts = () => {
    setNotFound(false);

    if (inicioEmissao > fimEmissao) {
      setErro(
        "A data final de emissão deve ser posterior à data de início. Selecione o período novamente."
      );
    } else if (inicioPrevisao > fimPrevisao) {
      setErro(
        "A data final de previsão deve ser posterior à data de início. Selecione o período novamente."
      );
      return;
    } else {
      setErro("");
      setHead(columns);
      console.log(data);

      // Apply filters to data
      const filteredData = data.filter((item) => {
        const matchesOficina = oficina
          ? item.oficina.toLowerCase().includes(oficina.toLowerCase())
          : true;
        const matchesRef = ref
          ? item.ref.toLowerCase().includes(ref.toLowerCase())
          : true;
        const matchesSaldo = showSaldo
          ? parseInt(item.qtSaldo, 10) !== 0
          : true;

        const parseDate = (dateStr) => {
          const [day, month, year] = dateStr.split("/").map(Number);
          return new Date(year, month - 1, day);
        };

        const itemEmiIni = parseDate(item.emiIni);
        const itemEmiFim = parseDate(item.emiFim);
        const itemPreviIni = parseDate(item.previIni);
        const itemPreviFim = parseDate(item.previFim);

        const matchesEmissao =
          !inicioEmissao ||
          !fimEmissao ||
          (itemEmiFim >= inicioEmissao && itemEmiIni <= fimEmissao);

        const matchesPrevisao =
          !inicioPrevisao ||
          !fimPrevisao ||
          (itemPreviFim >= inicioPrevisao && itemPreviIni <= fimPrevisao);

        return (
          matchesOficina &&
          matchesRef &&
          matchesSaldo &&
          matchesEmissao &&
          matchesPrevisao
        );
      });

      if (filteredData.length === 0) {
        setNotFound(true);
        return;
      }

      console.log(`Data: ${filteredData}`);
      setRows(filteredData);
      setTotals({
        qtRemetida: filteredData.reduce(
          (sum, item) => sum + parseInt(item.qtRemetida, 10),
          0
        ),
        qtRetornada: filteredData.reduce(
          (sum, item) => sum + parseInt(item.qtRetornada, 10),
          0
        ),
        qtSaldo: filteredData.reduce(
          (sum, item) => sum + parseInt(item.qtSaldo, 10),
          0
        ),
      });
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container wrap="wrap">
          <Grid
            item
            xs={6}
            sm={7}
            md={8}
            lg={10}
            style={{ marginTop: "-1.6rem" }}
          >
            <h3 className={css(styles.pageTitle)}>{pgTitle}</h3>
          </Grid>
          {/* <Grid
            item
            xs={6}
            sm={5}
            md={4}
            lg={2}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              marginTop: "-1.3rem",
            }}
          >
            {head && head.length > 0 ? (
              <>
                <button
                  onClick={
                    totalChars > 85 || headChars > 90
                      ? getPDFLandscape
                      : openModal
                  }
                  className={css(styles.btn)}
                  style={{ marginRight: 5 }}
                  title="Exportar como PDF"
                >
                  <PictureAsPdfIcon />
                </button>
                <CSVLink
                  headers={fullHeadCSV}
                  data={fullReportCSV}
                  separator=";"
                  // enclosingCharacter=""
                  filename={`CURVA_ABC_CLIENTES_${formatIni.replace(
                    /\//g,
                    "-"
                  )}_${formatFim.replace(/\//g, "-")}.csv`}
                  className={css(styles.btn)}
                  title="Exportar como CSV"
                >
                  <TableViewIcon />
                </CSVLink>
              </>
            ) : (
              ""
            )}
          </Grid> */}
        </Grid>
        <hr
          style={{ color: "#eee", marginTop: "-0.55rem", marginBottom: "1rem" }}
        />

        <FilterOficinas
          iniEmissaoValue={inicioEmissao}
          iniEmissaoSet={updateInicioEmissao}
          fimEmissaoValue={fimEmissao}
          fimEmissaoSet={updateFimEmissao}
          iniPrevisaoValue={inicioPrevisao}
          iniPrevisaoSet={updateInicioPrevisao}
          fimPrevisaoValue={fimPrevisao}
          fimPrevisaoSet={updateFimPrevisao}
          ofcValue={oficina}
          ofcSet={updateOficina}
          refValue={ref}
          refSet={updateRef}
          switchValue={showSaldo}
          switchSet={switchSaldo}
          search={searchProducts}
          erro={erro}
          loading={loading}
        />
      </Box>

      {head && head.length > 0 && !notFound ? (
        <Box
          sx={{
            width: "100%",
            display: "grid",
          }}
        >
          <CustomDatagrid
            head={head}
            row={rows}
            totals={totals}
            loading={loading}
          />
        </Box>
      ) : notFound || erro.length ? (
        <Box
          width={"100%"}
          height={"45vh"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid
            item
            xs={12}
            md={12}
            height={{ md: "20%", xs: "50%" }}
            display={"inline-flex"}
            textAlign={"center"}
            justifyContent={"center"}
            alignItems={"center"}
            bgcolor={"lightyellow"}
            borderRadius={5}
            padding={5}
            color={Colors.noResultFont}
          >
            <p
              style={{
                color: "#4b4b4b",
                // fontFamily: "Poppins",
                fontSize: 14,
              }}
            >
              Sua pesquisa não retornou nenhum resultado.
            </p>
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

const styles = StyleSheet.create({
  pageTitle: {
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#4b4b4b",
  },
  head: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: 1.5,
  },

  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    textAlign: "center",
    textDecoration: "none",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 4,
    marginTop: "-0.5rem",
    cursor: "pointer",

    padding: "5px 8px 3px 8px",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
});

export default PosicaoOficinas;
