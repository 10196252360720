import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";

import { StyleSheet, css } from "aphrodite";
import logoSiga from "../../assets/imgs/SIGAtitlecolor.png";

// const API_URL = localStorage.getItem("apiUrl");
// const userId = localStorage.getItem("userId");

const asyncLocalStorage = {
  async getItem(key: any) {
    await null;
    return localStorage.getItem(key);
  },
};

const MainPage = (): JSX.Element => {
  // const [API_URL, setCurrentAPI] = useState<any>("");
  // const [userId, setCurrentUserID] = useState<any>("");

  let API_URL: any;
  let userId: any;

  useEffect(() => {
    // Check if the page has already been reloaded
    const hasReloaded = localStorage.getItem("hasReloaded");

    if (!hasReloaded) {
      localStorage.setItem("hasReloaded", "true"); // Mark as reloaded
      window.location.reload(); // Reload the page
    } else {
      // Your logic for API calls and user setup
      getAPI();
      getUserID();

      if (API_URL === null || userId === null) {
        window.location.reload();
      }

      console.log(API_URL);
      console.log(userId);
    }
  }, []);

  function getAPI() {
    asyncLocalStorage.getItem("apiUrl").then((response) => {
      console.log(response);
      API_URL = response;
    });
  }

  function getUserID() {
    asyncLocalStorage.getItem("userId").then((response) => {
      console.log(response);
      userId = response;
    });
  }

  return (
    <>
      <Box
        width={"100%"}
        height={"80vh"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {/* Bloco de exibição da logomarca da empresa */}
        {/* <p className={css(styles.templateText)}>(Logo da empresa aqui)</p> */}
        <Box
          component={"img"}
          src={logoSiga}
          alt={"Logo SIGA"}
          width={{ xs: "90%", sm: "50%", md: "35%" }}
        />
      </Box>
    </>
  );
};

const styles = StyleSheet.create({
  templateText: {
    color: "#4b4b4b",
    fontFamily: "Poppins",
    fontSize: 20,
    fontWeight: 600,
  },
});

export default MainPage;
