export const columns = [
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "left",
    field: "oficina",
    headerName: "Oficina",
    headerAlign: "left",
    isNumber: false,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "left",
    field: "ref",
    headerName: "Referência",
    headerAlign: "left",
    isNumber: false,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "left",
    field: "nome",
    headerName: "Descrição",
    headerAlign: "left",
    isNumber: false,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "right",
    field: "qtRemetida",
    headerName: "Qt. Remetida",
    headerAlign: "right",
    isNumber: true,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "right",
    field: "qtRetornada",
    headerName: "Qt. Retornada",
    headerAlign: "right",
    isNumber: true,
    sortable: false,
    minWidth: 150,
  },
  {
    headerClassName: "datagrid_head",
    flex: 1,
    align: "right",
    field: "qtSaldo",
    headerName: "Qt. Saldo",
    headerAlign: "right",
    isNumber: true,
    sortable: false,
    minWidth: 150,
  },
];

export const data = [
  {
    oficina: "Oficina Exemplo 1",
    ref: "Referência 1",
    nome: "PRODUTO A",
    qtRemetida: "150",
    qtRetornada: "100",
    qtSaldo: "50",
    emiIni: "01/01/2025",
    emiFim: "15/01/2025",
    previIni: "01/01/2025",
    previFim: "25/01/2025",
  },
  {
    oficina: "Oficina Exemplo 1",
    ref: "Referência 2",
    nome: "PRODUTO B",
    qtRemetida: "200",
    qtRetornada: "200",
    qtSaldo: "0",
    emiIni: "01/01/2025",
    emiFim: "16/01/2025",
    previIni: "01/01/2025",
    previFim: "26/01/2025",
  },
  {
    oficina: "Oficina Exemplo 2",
    ref: "Referência 1",
    nome: "PRODUTO A",
    qtRemetida: "300",
    qtRetornada: "300",
    qtSaldo: "0",
    emiIni: "01/01/2025",
    emiFim: "17/01/2025",
    previIni: "01/01/2025",
    previFim: "27/01/2025",
  },
  {
    oficina: "Oficina Exemplo 2",
    ref: "Referência 3",
    nome: "PRODUTO C",
    qtRemetida: "480",
    qtRetornada: "160",
    qtSaldo: "320",
    emiIni: "01/01/2025",
    emiFim: "18/01/2025",
    previIni: "01/01/2025",
    previFim: "28/01/2025",
  },
  {
    oficina: "Oficina Exemplo 2",
    ref: "Referência 4",
    nome: "PRODUTO D",
    qtRemetida: "220",
    qtRetornada: "90",
    qtSaldo: "130",
    emiIni: "01/01/2025",
    emiFim: "19/01/2025",
    previIni: "01/01/2025",
    previFim: "29/01/2025",
  },
  {
    oficina: "Oficina Exemplo 2",
    ref: "Referência 5",
    nome: "PRODUTO E",
    qtRemetida: "400",
    qtRetornada: "400",
    qtSaldo: "0",
    emiIni: "01/01/2025",
    emiFim: "06/02/2025",
    previIni: "01/01/2025",
    previFim: "16/02/2025",
  },
  {
    oficina: "Oficina Exemplo 2",
    ref: "Referência 7",
    nome: "PRODUTO G",
    qtRemetida: "560",
    qtRetornada: "320",
    qtSaldo: "240",
    emiIni: "01/01/2025",
    emiFim: "07/02/2025",
    previIni: "01/01/2025",
    previFim: "17/02/2025",
  },
  {
    oficina: "Oficina Exemplo 3",
    ref: "Referência 1",
    nome: "PRODUTO A",
    qtRemetida: "100",
    qtRetornada: "30",
    qtSaldo: "70",
    emiIni: "01/01/2025",
    emiFim: "08/02/2025",
    previIni: "01/01/2025",
    previFim: "18/02/2025",
  },
  {
    oficina: "Oficina Exemplo 3",
    ref: "Referência 2",
    nome: "PRODUTO B",
    qtRemetida: "310",
    qtRetornada: "200",
    qtSaldo: "110",
    emiIni: "01/01/2025",
    emiFim: "09/02/2025",
    previIni: "01/01/2025",
    previFim: "19/02/2025",
  },
  {
    oficina: "Oficina Exemplo 3",
    ref: "Referência 6",
    nome: "PRODUTO F",
    qtRemetida: "540",
    qtRetornada: "540",
    qtSaldo: "0",
    emiIni: "01/01/2025",
    emiFim: "10/02/2025",
    previIni: "01/01/2025",
    previFim: "20/02/2025",
  },
];

export const totalizador = {
  qtRemetida: "3260",
  qtRetornada: "2340",
  qtSaldo: "920",
};
