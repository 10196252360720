import React, { useState, useRef, useEffect } from "react";

import { Grid } from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";

import { StyleSheet, css } from "aphrodite";

import "../../../assets/styles/muiComponents.css";

function CustomDatagrid(props) {
  const setHeaders = props.head;
  const setRows = props.row;

  let setTotals = [];
  setTotals.push(props.totals);

  const topGridContainerRef = useRef(null);
  const bottomGridContainerRef = useRef(null);

  const numberComparator = (v1, v2) =>
    v1.replace(".", "").replace(",", ".") -
    v2.replace(".", "").replace(",", ".");

  setHeaders.forEach((sr) => {
    if (sr.isNumber) {
      sr["sortComparator"] = numberComparator;
    } else {
      sr["isNumber"] = false;
    }
  });

  function generateRandom() {
    let length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const syncScroll = (sourceRef, targetRef) => {
    if (sourceRef.current && targetRef.current) {
      const sourceScroll = sourceRef.current.scrollLeft;
      targetRef.current.scrollLeft = sourceScroll;
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      const topScrollElement = topGridContainerRef.current?.querySelector(
        ".MuiDataGrid-virtualScroller"
      );
      const bottomScrollElement = bottomGridContainerRef.current?.querySelector(
        ".MuiDataGrid-virtualScroller"
      );

      console.log("Top Scroller:", topScrollElement);
      console.log("Bottom Scroller:", bottomScrollElement);

      if (topScrollElement && bottomScrollElement) {
        const handleTopScroll = () =>
          (bottomScrollElement.scrollLeft = topScrollElement.scrollLeft);
        const handleBottomScroll = () =>
          (topScrollElement.scrollLeft = bottomScrollElement.scrollLeft);

        topScrollElement.addEventListener("scroll", handleTopScroll);
        bottomScrollElement.addEventListener("scroll", handleBottomScroll);

        return () => {
          topScrollElement.removeEventListener("scroll", handleTopScroll);
          bottomScrollElement.removeEventListener("scroll", handleBottomScroll);
        };
      }
    }, 500); // Delay execution

    return () => clearTimeout(timeout); // Cleanup timeout on unmount
  }, []);

  return (
    <>
      {window.screen.width <= 768 ? (
        <div
          ref={topGridContainerRef}
          style={{
            overflow: "auto",
            // maxHeight: 300
          }}
        >
          <DataGrid
            autoHeight
            disableColumnFilter
            columns={setHeaders}
            columnHeaderHeight={30}
            getRowId={(row) => generateRandom()}
            rows={setRows}
            rowHeight={25}
            isRowSelectable={() => false}
            hideFooter
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            loading={props.loading}
            slotProps={{
              noResultsOverlay: {
                style: { color: "#4b4b4b", fontSize: 14 },
              },
              row: {
                style: { color: "#4b4b4b", fontSize: 12 },
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0
                ? "even_row"
                : "odd_row"
            }
          />
        </div>
      ) : (
        <DataGrid
          autoHeight
          disableColumnFilter
          columns={setHeaders}
          columnHeaderHeight={30}
          getRowId={(row) => generateRandom()}
          rows={setRows}
          rowHeight={25}
          isRowSelectable={() => false}
          hideFooter
          disableRowSelectionOnClick
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          loading={props.loading}
          slotProps={{
            noResultsOverlay: {
              style: { color: "#4b4b4b", fontSize: 14 },
            },
            row: {
              style: { color: "#4b4b4b", fontSize: 12 },
            },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even_row" : "odd_row"
          }
        />
      )}

      <Grid container wrap="wrap">
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: "#669966",
            borderRadius: "3px 3px 0 0",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 25,
            marginTop: "1rem",
          }}
        >
          <p className={css(styles.head)}>TOTAL GERAL</p>
        </Grid>
      </Grid>

      {window.screen.width <= 768 ? (
        <div
          ref={bottomGridContainerRef}
          style={{
            overflow: "auto",
            // maxHeight: 100
          }}
        >
          <DataGrid
            autoHeight
            columns={setHeaders}
            columnHeaderHeight={0}
            getRowId={(row) => generateRandom()}
            rows={setTotals}
            rowHeight={25}
            row
            isRowSelectable={() => false}
            hideFooter
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            slotProps={{
              row: {
                style: {
                  backgroundColor: "#fff",
                  color: "#4b4b4b",
                  fontSize: 12,
                  // fontWeight: "600",
                  // fontFamily: "Poppins",
                },
              },
            }}
          />
        </div>
      ) : (
        <DataGrid
          autoHeight
          columns={setHeaders}
          columnHeaderHeight={0}
          getRowId={(row) => generateRandom()}
          rows={setTotals}
          rowHeight={25}
          row
          isRowSelectable={() => false}
          hideFooter
          disableRowSelectionOnClick
          localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          slotProps={{
            row: {
              style: {
                backgroundColor: "#fff",
                color: "#4b4b4b",
                fontSize: 12,
                // fontWeight: "600",
                // fontFamily: "Poppins",
              },
            },
          }}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  head: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    color: "#fff",
    fontWeight: 600,
    fontSize: 14,
    // fontFamily: "Poppins",
  },
});

export default CustomDatagrid;
