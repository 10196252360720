import * as React from "react";
import Select from "react-select";

import { Box, Grid, FormControlLabel, Switch, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { borderRadius, styled } from "@mui/system";

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

import { StyleSheet, css } from "aphrodite";

import "../../assets/styles/muiComponents.css";
import { red } from "@mui/material/colors";

function FilterProcessos(props) {
  const customStyles = {
    container: (base) => ({
      ...base,
      marginTop: window.screen.width > 546 ? "0.49rem" : "",
    }),
    placeholder: (base) => ({
      ...base,
      // marginTop: -2,
      color: "#cacaca",
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
    input: (base) => ({
      ...base,
      marginTop: -2,
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
    menuList: (base) => ({
      ...base,
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
    multiValue: (base) => ({
      ...base,
      // fontFamily: "Poppins",
      fontSize: 12,
    }),
  };

  return (
    <Box sx={{ flexGrow: 1, justifyContent: "center" }}>
      <Grid
        container
        wrap="wrap"
        spacing={window.screen.width > 546 ? 2 : 1}
        style={{
          marginBottom: "1rem",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <TextField
            name="referencia"
            id="referencia"
            placeholder={"Referência"}
            value={props.refValue}
            onChange={(ev) => props.refSet(ev.target.value)}
            type="search"
            size="small"
            fullWidth
            className={css(styles.input)}
            sx={{
              marginTop: "0.5rem",
              "& .MuiInputBase-root": {
                height: 38,
                // fontFamily: "Poppins",
                fontSize: 12,
              },
            }}
          ></TextField>
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <Select
            name="processos"
            placeholder="Processos"
            isMulti
            options={props.processList}
            value={props.processValue}
            onChange={(ev) => props.processSet(ev)}
            styles={customStyles}
            noOptionsMessage={() => "Sem resultados"}
          ></Select>
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2}>
          <Select
            name="tipo"
            placeholder="Tipo"
            isMulti
            options={props.tipoList}
            value={props.tipoValue}
            onChange={(ev) => props.tipoSet(ev)}
            styles={customStyles}
            noOptionsMessage={() => "Sem resultados"}
          ></Select>
        </Grid>

        <Grid item xs={6} sm={2.5} md={2} lg={1.25}>
          <DatePicker
            label="Emissão - Início"
            sx={{
              width: "100%",
              marginTop: "0.5rem",
              "& .MuiInputBase-root": {
                color: "#4b4b4b",
                height: "2.35rem",
                // fontFamily: "Poppins",
                fontSize: 10,
              },
              "& .MuiFormLabel-root": {
                color: "#cacaca",
                // marginTop: -0.2,
                // fontFamily: "Poppins",
                fontSize: 12,
                zIndex: 0,
              },
            }}
            slotProps={{ textField: { size: "small" } }}
            value={props.iniEmissaoValue}
            onChange={(ev) => props.iniEmissaoSet(ev)}
          />
        </Grid>
        <Grid item xs={6} sm={2.5} md={2} lg={1.25}>
          <DatePicker
            label="Emissão - Fim"
            sx={{
              width: "100%",
              marginTop: "0.5rem",
              "& .MuiInputBase-root": {
                color: "#4b4b4b",
                height: "2.35rem",
                // fontFamily: "Poppins",
                fontSize: 10,
              },
              "& .MuiFormLabel-root": {
                color: "#cacaca",
                // marginTop: -0.2,
                // fontFamily: "Poppins",
                fontSize: 12,
                zIndex: 0,
              },
            }}
            slotProps={{ textField: { size: "small" } }}
            value={props.fimEmissaoValue}
            onChange={(ev) => props.fimEmissaoSet(ev)}
          ></DatePicker>
        </Grid>

        <Grid item xs={6} sm={2.5} md={2} lg={1.25}>
          <DatePicker
            label="Previsão - Início"
            sx={{
              width: "100%",
              marginTop: "0.5rem",
              "& .MuiInputBase-root": {
                color: "#4b4b4b",
                height: "2.35rem",
                // fontFamily: "Poppins",
                fontSize: 10,
              },
              "& .MuiFormLabel-root": {
                color: "#cacaca",
                // marginTop: -0.2,
                // fontFamily: "Poppins",
                fontSize: 12,
                zIndex: 0,
              },
            }}
            slotProps={{ textField: { size: "small" } }}
            value={props.iniPrevisaoValue}
            onChange={(ev) => props.iniPrevisaoSet(ev)}
          />
        </Grid>
        <Grid item xs={6} sm={2.5} md={2} lg={1.25}>
          <DatePicker
            label="Previsão - Fim"
            sx={{
              width: "100%",
              marginTop: "0.5rem",
              "& .MuiInputBase-root": {
                color: "#4b4b4b",
                height: "2.35rem",
                // fontFamily: "Poppins",
                fontSize: 10,
              },
              "& .MuiFormLabel-root": {
                color: "#cacaca",
                // marginTop: -0.2,
                // fontFamily: "Poppins",
                fontSize: 12,
                zIndex: 0,
              },
            }}
            slotProps={{ textField: { size: "small" } }}
            value={props.fimPrevisaoValue}
            onChange={(ev) => props.fimPrevisaoSet(ev)}
          ></DatePicker>
        </Grid>

        <Grid
          item
          xs={2}
          sm={2}
          md={1}
          lg={0.5}
          style={{ marginTop: "-1.5rem" }}
        >
          <button
            onClick={() => props.search()}
            className={
              props.loading ? css(styles.btnDisabled) : css(styles.btn)
            }
            title="Buscar"
          >
            <SearchOutlinedIcon />
          </button>
        </Grid>

        <Grid
          container
          wrap="wrap"
          style={{
            paddingBottom: "1rem",
            borderBottom: "solid 0.5px #eee",
            justifyContent: "center",
          }}
        >
          <p className={css(styles.erro)}>{props.erro}</p>
        </Grid>
      </Grid>
    </Box>
  );
}

const styles = StyleSheet.create({
  input: {
    fontFamily: "Poppins !important",
    fontSize: 12,
    fontWeight: 400,
    color: "#808080",
    transitionDuration: "0.2s",
  },
  btn: {
    backgroundColor: "#669966",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#669966",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "2rem",
    transitionDuration: "0.2s",

    ":hover": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
    },
    ":active": {
      color: "#669966",
      backgroundColor: "transparent !important",
      borderColor: "#669966 !important",
      opacity: 0.6,
    },
  },
  btnDisabled: {
    backgroundColor: "#B3B3B3",
    color: "#fff",
    fontFamily: "Poppins",
    fontSize: 12,
    fontWeight: 600,
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "#B3B3B3",
    borderRadius: 6,

    display: "flex !important",
    justifyContent: "center !important",
    alignContent: "center !important",
    padding: "0.5rem 0.3rem 0.42rem 0.4rem",
    width: "100%",
    marginTop: "2rem",
  },
  erro: {
    // fontFamily: "Poppins",
    fontSize: 14,
    color: "red",
    textAlign: "center",
    fontWeight: 600,
    marginTop: "1rem",
    marginBottom: "-0.5rem",
  },
});

export default FilterProcessos;
